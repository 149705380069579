import React from 'react'
import './about-us-styles.scss'
import _ from 'lodash'
import { getUrlMedia } from '../../../../helpers'
import { Button } from '../../../../components'

const AboutUS = ({ t, data }) => (
  <div className="container-fluid">
    <div className="container-xxl aboutUsHome__container">
      <div className="row">
        <div className="col-sm-1 col-12"></div>
        <div className="col-sm-5 col-12">
          <div className="appendix">
            <div className="aboutUsHome__image">
              <img src={getUrlMedia(_.get(data, 'image'))} alt="" />
            </div>
          </div>
        </div>
        <div className="col-sm-1 col-12"></div>
        <div className="col-sm-5 col-12 aboutUsHome__content">
          <span className="aboutUsHome__tag">{t('home.about.tag')}</span>
          <h4>{_.get(data, 'title')}</h4>
          <p>{_.get(data, 'description')}</p>
          <Button text={t('home.about.buttonText')} link="/about-us/" />
        </div>
      </div>
    </div>
  </div>
)

export default AboutUS
