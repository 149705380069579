import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import './home-styles.scss'
import {
  Cta,
  CarouselCards,
  SliderMobile,
  CardWithPicture,
  CardProduct,
  Spinner,
  Testimonials,
  BlockLinks,
  Seo,
} from '../../components'
import {
  OurApproach, OurPartners, AboutUs, HeroImage,
} from './components'
import cta from '../../assets/images/home/Lets_talk_about_your_future.jpeg'
// import {
//   homeProductData,
// } from '../../data'

const HomeView = ({ data, loading }) => {
  const breakpoints = useBreakpoint()
  const { t, i18n } = useTranslation()    
  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <div className="home__container">
      <Seo title={`${t('seo.title')} Home`} />
      <HeroImage t={t} data={data.content} />      
      {!breakpoints.sm && (
        <BlockLinks
          data={_.get(data, 'content.you_matter')}
          grayBg={true}
          url={'/experience'}
          title={t('home.matter.title')}
          tag={t('home.matter.tag')}
          titleDown={true}
        />
      )}
      {breakpoints.sm && (
        <div className="home__matter">
          <SliderMobile
            title={t('home.matter.title')}
            tag={t('home.matter.tag')}
            totalItems={_.get(data, 'content.you_matter').length}
          >
            {_.map(_.get(data, 'content.you_matter'), (item) => (
              <div key={item.name} className="home__sliderOurMatter">
                <CardWithPicture
                  data={item}
                  i18n={i18n}
                  parentLink={'experience'}
                  featured_image={_.get(item, 'thumbnail')}
                />
              </div>
            ))}
          </SliderMobile>
        </div>
      )}
     
      <BlockLinks
        data={_.get(data, 'content.our_products')}
        roundBottomRight
        absolute={true}
        url={'/products'}
        title={t('home.products.title')}
        tag={t('home.products.tag')}
        t={t}
        titleDown={true}
      />
      {process.env.GATSBY_COUNTRY === 'in' && (
        <Testimonials data={_.get(data, 'content.testimonials')} />
      )}
      
      <OurApproach t={t} data={_.get(data, 'content.our_approach')} />
      <OurPartners t={t} />
      <AboutUs data={_.head(_.get(data, 'content.about_us'))} t={t} />

      <div className="container-xxl p-0">
        <Cta
          img={cta}
        />
      </div>
    </div>
  )
}

export default HomeView
